import React from 'react'

const SelectInput = (props) => {
    return (
        <div className={props.className}>
            <label htmlFor={props.label}>{props.label}</label>
            <select >
                <option value="first">first</option>
                <option value="first">second</option>
                <option value="first">thired</option>
                <option value="first">four</option>
                <option value="first">five</option>
            </select>
        </div>
    )
}
export default SelectInput;





